import store from "@/store";
import {
  getModule,
  Module,
  VuexModule,
  Mutation
} from "vuex-module-decorators";
import APP_CONST from "@/constants/AppConst";

@Module({
  namespaced: true,
  name: "siteSessionStore",
  store,
  dynamic: true
})

class SiteSessionModule extends VuexModule {

    public addSiteDetails:any = [];
    dropdownValue = {showSelectLabel: true, label: '', id: 0, value: 'Select', required: true, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [] as any[]};
    statesDropDown : any = [JSON.parse(JSON.stringify(this.dropdownValue))];

    get siteSessionDetails() {
        return this.addSiteDetails;
    }

    get siteSessionStatesDD() {
      return this.statesDropDown;
    }

    @Mutation
    mutateSiteSessionData() {
      this.addSiteDetails.push(JSON.parse(JSON.stringify(APP_CONST.ADD_SITE_DETAILS)));
      this.statesDropDown.push(JSON.parse(JSON.stringify(this.dropdownValue)))
    }
  
    @Mutation
    mutateStatesDD(dropdown : any) {
      this.statesDropDown = dropdown;
    }

    @Mutation
    mutateStatesDDByIndex(dropdown:{value: any, index: number}) {
      this.statesDropDown[dropdown.index] = dropdown.value;
    }

    @Mutation
    clearSiteSessionData() {
        this.addSiteDetails = [];
    }

    @Mutation
    resetDropDown(){
      this.statesDropDown = [JSON.parse(JSON.stringify(this.dropdownValue))];
    }
}

export default getModule(SiteSessionModule);
